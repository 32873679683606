@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");


:root {
 --navBg: rgba(34, 139, 34, 0.8); /* Vert semi-transparent */
 --navTextColor: #fff; /* Couleur du texte */
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  background-color: #fff;
  /*background-color: var(--navBg);
  background-image: url('../public/bg-white1.jpg');
  background-size: cover;
  background-position: center;*/
}

