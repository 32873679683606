/* Styles globaux */
/*
body {
  background-color: #f4f6f8;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
*/
/* Container principal */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* Boîte de connexion */
.login-box {
  width: 100%;
  max-width: 500px;
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Titre */
.login-title {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

/* Formulaire */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Lien mot de passe oublié */
.forgot-password {
  display: block;
  margin-top: 10px;
  color: #1976d2;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

/* Responsive */
@media (max-width: 480px) {
  .login-box {
    width: 90%;
    padding: 20px;
  }
}
