:root {
  /*--navBg: #060b26;*/
  --navBg: rgba(34, 139, 34, 0.8); /* Vert semi-transparent */
    --navTextColor: #fff; /* Couleur du texte */
}

.navbar {
  /*
  background-color: #333;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  position: relative;
  */
  background-color: var(--navBg);
  background-image: url('../../public/bg-vigne.jpeg'); /* Image de fond */
  background-size: cover; /* Couvrir toute la navbar */
  background-position: center; /* Centrer l'image */
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 1000; /* Assurez-vous que la navbar a un z-index élevé */
  position: relative;
  color: var(--navTextColor); /* Couleur du texte */
}
.navbar-title {
  color: var(--navTextColor);
  margin-left: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}
 .navbar-title:hover {
  color: #d4f1c5; /* Vert clair au survol */
}
.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: var(--navTextColor);
}
.menu-bars:hover {
  color: #d4f1c5; /* Vert clair au survol */
}
.nav-menu {
  background-color: rgba(0, 0, 0, 0.9);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 999; /* Assurez-vous que le menu a un z-index élevé */
  overflow-y: auto; /* Permettre le défilement vertical */
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #d4f1c5; /* Vert clair au survol */
  color: #333; /* Couleur du texte au survol */
}

.nav-menu-items {
  width: 100%;
  padding: 0;
}

.navbar-toggle {
  background-color: #000000;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.spanNav {
  margin-left: 16px;
}

.nav-section-title {
  color: #fff;
  margin: 10px 0;
  padding: 10px;
  font-size: 1.2rem;
}

.navbar-title {
  color: #fff;
  margin-left: 20px;
}

.nav-section-title:hover {
  background-color: #992525;
}

.nav-section-title.active {
  background-color: #000000;
}

.nav-section-title.active:hover {
  background-color: #0d1115;
}

/* LOGOUT */
.logout-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  color: black !important; /* Couleur du texte */
  font-size: 2.5rem;
  cursor: pointer;
  border-radius: 8px; /* Coins arrondis pour un design plus moderne */
  padding: 8px; /* Espacement interne */
  transition: color 0.3s ease;
}

.logout-button:hover {
  color: black !important; /* Rouge clair au survol */
}